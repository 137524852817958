<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Vouchers.ModalEditProfiles.Main_Title')"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveVoucher"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      modalSize="large"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <div class="form-companies columns">
          <div class="search-add column-search column">
            <div class="search-wrapper has-margin-bottom">
              <!-- search input -->
              <input
                v-on:keyup.enter="search"
                class="input"
                type="text"
                v-model="searchTerm"
                placeholder="Search for options"
              />
              <!-- search button -->
              <button @click="search" class="button is-primary">
                <a>
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'search']"
                      style="color: white"
                    />
                  </span>
                </a>
              </button>
            </div>
            <div class="add-list">
              <ui-loader v-if="isLoading === true" />
              <div
                class="row"
                v-for="(item, index) in filteredItems"
                :key="index"
              >
                <div class="card">
                  <div class="card-content">
                    <div class="image-wrapper">
                      <img class="image" :src="item.Image" alt="" />
                    </div>
                    <div class="info-wrapper">
                      <div class="item">{{ item.FullName }}</div>
                      <div class="item">{{ item.Email }}</div>
                    </div>
                    <div class="button-group">
                      <button
                        @click="addProfile(item)"
                        class="button is-small is-success"
                      >
                        <a>
                          <span class="icon">
                            <font-awesome-icon
                              :icon="['fas', 'plus']"
                              style="color: white"
                            />
                          </span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-options column">
            <div class="selected-options-wrapper">
              <div class="title is-3">
                {{ $t('Components.Vouchers.ModalEditProfiles.Sub_Title') }}
              </div>

              <message
                v-if="mVoucher.Profiles.length === 0"
                :message="'No profiles selected'"
              />

              <div v-if="mVoucher.Profiles.length > 0" class="list">
                <div
                  class="row"
                  v-for="(profile, index) in mVoucher.Profiles"
                  :key="index"
                >
                  <div class="card">
                    <div class="card-content">
                      <div class="image-wrapper">
                        <img class="image" :src="profile.Image" alt="" />
                      </div>
                      <div class="info-wrapper">
                        <div class="item">{{ profile.FullName }}</div>
                        <div class="item">{{ profile.Email }}</div>
                      </div>
                      <div class="button-group">
                        <a @click="deleteProfile(index)">
                          <span class="icon">
                            <font-awesome-icon
                              :icon="['fas', 'trash-alt']"
                              style="color: red"
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import profileProvider from '@/providers/profile'
import voucherProvider from '@/providers/voucher'

import Message from '@/components/UI/Message'

export default {
  components: {
    message: Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      locationId: Number(this.$route.params.locationId),
      profiles: [],
      searchTerm: '',
      list: [],
      isLoading: false,
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mVoucher: null,
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    filteredItems() {
      let result = []
      if (this.profiles.length > 0) {
        for (let i = 0; i < this.profiles.length; i++) {
          let profileIndex = this.mVoucher.Profiles.findIndex(
            (p) => p.ProfileId === this.profiles[i].Id
          )
          if (profileIndex === -1) {
            result.push(this.profiles[i])
          }
        }
      }
      return result
    },
  },

  created() {
    let self = this

    self.mVoucher = JSON.parse(JSON.stringify(self.voucher))
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    search() {
      let self = this
      this.isLoading = true
      this.profiles = []
      profileProvider.methods
        .getProfiles(this.searchTerm, 1, 25, this.locationId)
        .then((response) => {
          self.profiles = response.data.Results
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    addProfile(profile) {
      this.mVoucher.Profiles.push({
        VoucherId: this.voucher.Id,
        ProfileId: profile.Id,
        ProfileName: profile.FullName,
        ProfileEmail: profile.Email,
      })
    },

    deleteProfile(index) {
      Vue.delete(this.mVoucher.Profiles, index)
    },

    saveVoucher() {
      this.isSaving = true

      this.mVoucher.LocationId = this.locationId

      voucherProvider.methods
        .saveVoucher(this.mVoucher)
        .then((response) => {
          if (response.status === 200) {
            this.setVoucher(response.data)
            this.isSavingSuccess = true

            setTimeout(() => {
              this.$emit('voucherEdited', response.data)
              this.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error)
          this.isSavingError = true
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.form-companies {
  .search-add {
    display: flex;
    flex-direction: column;
    &.column-search {
      width: 33%;
    }
    .search-wrapper {
      display: flex;
      .button {
        margin-left: 5px;
        &.green {
          background-color: $green;
        }
      }
    }
    .add-list {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border: 1px solid $grey-lighter;
      border-radius: 6px;
      height: 300px;
      .row {
        display: flex;
        align-items: center;
        padding: 10px;
        .card {
          width: 100%;
          .card-content {
            position: relative;
            display: flex;
            align-items: center;
            .image-wrapper {
              height: 50px;
              width: 50px;
              margin-right: 25px;
              .image {
                height: 100%;
                width: 100%;
                border-radius: 25px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
              }
            }
            .info-wrapper {
              display: flex;
              flex-direction: column;
            }
            .button-group {
              position: absolute;
              top: 15px;
              right: 15px;
              width: 21px;
              height: 21px;
              .button {
                padding: 0;
                width: 100%;
                height: 100%;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .selected-options-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      flex-grow: 0;
    }
    .list {
      flex-grow: 1;
      overflow-y: scroll;
      .row {
        position: relative;
        display: flex;
        padding: 10px;
        .card {
          width: 100%;
          .card-content {
            position: relative;
            display: flex;
            align-items: center;
            .image-wrapper {
              height: 50px;
              width: 50px;
              margin-right: 25px;
              .image {
                height: 100%;
                width: 100%;
                border-radius: 25px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
              }
            }
            .info-wrapper {
              display: flex;
              flex-direction: column;
            }
            .button-group {
              position: absolute;
              top: 15px;
              right: 15px;
              width: 21px;
              height: 21px;
              .button {
                padding: 0;
                width: 100%;
                height: 100%;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
